// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--2GJgl";
export var footStrip = "Footer-module--footStrip--1Q-zZ";
export var footerTop = "Footer-module--footerTop--3HWDg";
export var subscribe = "Footer-module--subscribe--2NDWj";
export var subBtn = "Footer-module--subBtn--1H-mn";
export var socialLinks = "Footer-module--socialLinks--2Btgo";
export var footerTopSpacer = "Footer-module--footerTopSpacer--mng0W";
export var footerBtmCont = "Footer-module--footerBtmCont--22ueO";
export var widefootMenu = "Footer-module--widefootMenu--3LZnL";
export var doniAccess = "Footer-module--doniAccess--kbz5d";
export var donBtn = "Footer-module--donBtn--40HkJ";
export var applyBtn = "Footer-module--applyBtn--ekp-2";