// extracted by mini-css-extract-plugin
export var myHeader = "Header-module--myHeader--3FIuE";
export var navi = "Header-module--navi--1P4oN";
export var sticky = "Header-module--sticky--3wkY3";
export var navBrand = "Header-module--navBrand--2dDe7";
export var logoInn = "Header-module--logoInn--3tu-4";
export var scroll = "Header-module--scroll--18L4Y";
export var headermenu = "Header-module--headermenu--3xz7J";
export var menuConatiner = "Header-module--menuConatiner--1xZOJ";
export var deskNavbar = "Header-module--deskNavbar--38UcV";
export var deskDropdown = "Header-module--deskDropdown--3dN_w";
export var dropbtn = "Header-module--dropbtn--ihsd8";
export var dropdownContent = "Header-module--dropdownContent--3N6vP";
export var menuHeads = "Header-module--menuHeads--3IbmH";
export var megaOuterList = "Header-module--megaOuterList--OKmjq";
export var megaLinks = "Header-module--megaLinks--1BdEM";
export var dropdownMenu = "Header-module--dropdown-menu--3m7Qi";
export var dropdownContentInn = "Header-module--dropdownContentInn--2z7k2";
export var dropdownContOne = "Header-module--dropdownContOne--2qAF4";
export var dropdownContTwo = "Header-module--dropdownContTwo--12-NW";
export var dropdownContThree = "Header-module--dropdownContThree--22qJ3";
export var dropdownContFour = "Header-module--dropdownContFour--J7uOb";
export var dropdownContFive = "Header-module--dropdownContFive--2_kZT";
export var menuBtns = "Header-module--menuBtns--qO68H";
export var donBtn = "Header-module--donBtn--AVEba";
export var appHBtn = "Header-module--appHBtn--253My";
export var srcBtn = "Header-module--srcBtn--3BKV9";
export var mobileNav = "Header-module--mobileNav--2Es6i";
export var widefootMenu = "Header-module--widefootMenu--301P4";